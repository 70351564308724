import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '../client/styles/global.css';

import * as Sentry from '@sentry/nextjs';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import React, { useEffect } from 'react';

import { AppProps } from 'next/app';
import { Auth0User } from '../types';
import AuthorizedApolloProvider from '../client/providers/AuthorizedApolloProvider';
import DrawContext from '../client/context/Draw';
import DrawLineString from '../client/DrawModes/LineMode';
import DrawMarker from '../client/DrawModes/MarkerMode';
import ErrorBoundary from '../client/components/ErrorBoundary';
import Head from 'next/head';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js';
import Router from 'next/router';
import { StoreProvider } from 'easy-peasy';
import firebase from '../firebase';
import mapboxgl from 'mapbox-gl';
import { omit } from 'lodash';
import store from '../client/redux';
import styles from '../client/DrawModes/styles.json';
import theme from '../client/theme';
import Script from 'next/script';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  enabled: process.env.NODE_ENV === 'production',
});

mapboxgl.accessToken = `${process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}`;

/* eslint-disable @typescript-eslint/no-explicit-any */
const onRedirectCallback = (appState: any) => {
  // Use Next.js's Router.replace method to replace the url
  Router.replace(appState?.returnTo || '/');
};
// eslint-disable-next-line @typescript-eslint/ban-types
const LoginListener: React.FC<{}> = ({ children }) => {
  const { user }: { user?: Auth0User } = useAuth0();
  useEffect(() => {
    const email = user?.email;
    if (email) {
      const storedEmail = localStorage.getItem('user_email');
      if (storedEmail !== email) {
        firebase.analytics().logEvent('tour_builder_login', { email });
        localStorage.setItem('user_email', email);
      }
    }
  }, [user?.email]);
  return <>{children}</>;
};

export default function App({ Component, pageProps, err }: AppProps & { err: Error }) {
  // generate unique transactionId and set as Sentry tag
  const transactionId = Math.random().toString(36).substr(2, 9);

  Sentry.configureScope((scope) => {
    scope.setTransactionName(transactionId);
  });
  const draw = new MapboxDraw({
    modes: {
      ...omit(MapboxDraw.modes, ['draw_polygon', 'draw_point', 'draw_line_string']),
      draw_marker: DrawMarker,
      draw_line: DrawLineString,
    },
    userProperties: true,
    styles,
  });

  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? ''}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? ''}
        redirectUri={process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
        onRedirectCallback={onRedirectCallback}
      >
        <Head>
          <title>ICAC SGT Admin</title>

          <link href="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css" rel="stylesheet" />
          <link href="/fonts/stylesheet.css" rel="stylesheet" />
          <link rel="shortcut icon" href="/favicon.ico" />
        </Head>

        <ThemeProvider theme={theme}>
          <CSSReset />

          <StoreProvider store={store}>
            <AuthorizedApolloProvider transactionId={transactionId}>
              <DrawContext.Provider value={draw}>
                <LoginListener>
                  <Component {...pageProps} err={err} />
                  <Script
                    src={`https://app.mapsindoors.com/mapsindoors/js/sdk/4.24.6/mapsindoors-4.24.6.js.gz?apikey=${process.env.NEXT_PUBLIC_MAPSINDOORS_API_KEY}`}
                  ></Script>
                </LoginListener>
              </DrawContext.Provider>
            </AuthorizedApolloProvider>
          </StoreProvider>
        </ThemeProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}
